<template>
    <p>
        f (x) =
        Log
        <span class="base">
        {{ b != "" && !isNaN(b) ? b : "b" }}
        </span>
        x
    </p>
</template>

<script>
export default {
  props: ['b'],
  name: 'logarithmic-function',
};
</script>