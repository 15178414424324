<template>
    <p>
        f (x) =
        {{ a != "" && !isNaN(a) ? a : "a" }}
        <span class="exp">
        x
        </span>
    </p>
</template>

<script>
export default {
  props: ['a'],
  name: 'exponential-function',
};
</script>