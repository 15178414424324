<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <form>
        <md-card>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field
                  :class="
                    form.functionTypeId.error.length > 0 ? 'md-invalid' : ''
                  "
                >
                  <label>{{
                    $t("device.slot.slotCalibrator.functionType")
                  }}</label>
                  <md-select
                    v-model="form.functionTypeId.value"
                    name="functionTypeId"
                  >
                    <md-option
                      v-for="functionType in functionTypes"
                      :value="functionType.id"
                      :key="functionType.id"
                      >{{ functionType.name }}</md-option
                    >
                  </md-select>
                  <span
                    v-if="form.functionTypeId.error.length > 0"
                    v-text="form.functionTypeId.error"
                    class="md-error"
                  ></span>
                </md-field>
              </div>
              <div
                v-if="
                  form.functionTypeId.value === 1 ||
                  form.functionTypeId.value === 2 ||
                  form.functionTypeId.value === 4
                "
                class="md-layout-item md-small-size-100 md-size-33"
              >
                <md-field :class="form.a.error.length > 0 ? 'md-invalid' : ''">
                  <label>a</label>
                  <md-input v-model="form.a.value" name="a"></md-input>
                  <span
                    v-if="form.a.error.length > 0"
                    v-text="form.a.error"
                    class="md-error"
                  ></span>
                </md-field>
              </div>
              <div
                v-if="
                  form.functionTypeId.value === 1 ||
                  form.functionTypeId.value === 2 ||
                  form.functionTypeId.value === 3
                "
                class="md-layout-item md-small-size-100 md-size-33"
              >
                <md-field :class="form.b.error.length > 0 ? 'md-invalid' : ''">
                  <label>b</label>
                  <md-input v-model="form.b.value" name="b"></md-input>
                  <span
                    v-if="form.b.error.length > 0"
                    v-text="form.b.error"
                    class="md-error"
                  ></span>
                </md-field>
              </div>
              <div
                v-if="form.functionTypeId.value === 2"
                class="md-layout-item md-small-size-100 md-size-33"
              >
                <md-field :class="form.c.error.length > 0 ? 'md-invalid' : ''">
                  <label>c</label>
                  <md-input v-model="form.c.value" name="c"></md-input>
                  <span
                    v-if="form.c.error.length > 0"
                    v-text="form.c.error"
                    class="md-error"
                  ></span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field
                  :class="
                    form.numberOfDecimals.error.length > 0 ? 'md-invalid' : ''
                  "
                >
                  <label>{{
                    $t("device.slot.slotCalibrator.numberOfDecimals")
                  }}</label>
                  <md-input
                    v-model="form.numberOfDecimals.value"
                    name="numberOfDecimals"
                  ></md-input>
                  <span
                    v-if="form.numberOfDecimals.error.length > 0"
                    v-text="form.numberOfDecimals.error"
                    class="md-error"
                  ></span>
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-100 function"
              >
                <template v-if="form.functionTypeId.value === 1">
                  <linear-function :a="form.a.value" :b="form.b.value" />
                </template>
                <template v-else-if="form.functionTypeId.value === 2">
                  <quadratic-function
                    :a="form.a.value"
                    :b="form.b.value"
                    :c="form.c.value"
                  />
                </template>
                <template v-else-if="form.functionTypeId.value === 3">
                  <logarithmic-function :b="form.b.value" />
                </template>
                <template v-else-if="form.functionTypeId.value === 4">
                  <exponential-function :a="form.a.value" />
                </template>
              </div>
              <div class="md-layout-item md-size-100 text-right">
                <md-button @click="handleSubmit('stay')" class="md-raised"
                  >Save</md-button
                >
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>

<script>
import { validator } from "../../mixins/validator.js";
import LinearFunction from "../../components/Functions/LinearFunction";
import QuadraticFunction from "../../components/Functions/QuadraticFunction";
import LogarithmicFunction from "../../components/Functions/LogarithmicFunction";
import ExponentialFunction from "../../components/Functions/ExponentialFunction";
export default {
  mixins: [validator],
  components: {
    LinearFunction,
    QuadraticFunction,
    LogarithmicFunction,
    ExponentialFunction,
  },
  data() {
    return {
      form: {
        functionTypeId: {
          value: 1,
          error: "",
          rules: [],
          default: 1,
        },
        numberOfDecimals: {
          value: 2,
          error: "",
          rules: [],
          default: 2,
        },
        a: {
          value: "",
          error: "",
          rules: [],
          default: "",
        },
        b: {
          value: "",
          error: "",
          rules: [],
          default: "",
        },
        c: {
          value: null,
          error: "",
          rules: [],
          default: null,
        },
      },
      functionTypes: [],
      editMode: null,
    };
  },
  watch: {
    "form.functionTypeId.value": function (val) {
      switch (val) {
        case 1:
          this.form.a.rules = [this.rules.required, this.rules.number];
          this.form.b.rules = [this.rules.required, this.rules.number];
          this.form.c.rules = [];
          break;
        case 2:
          this.form.a.rules = [this.rules.required, this.rules.number];
          this.form.b.rules = [this.rules.required, this.rules.number];
          this.form.c.rules = [this.rules.required, this.rules.number];
          break;
        case 3:
          this.form.a.rules = [];
          this.form.b.rules = [this.rules.required, this.rules.number];
          this.form.c.rules = [];
          break;
        case 4:
          this.form.a.rules = [this.rules.required, this.rules.number];
          this.form.b.rules = [];
          this.form.c.rules = [];
          break;
      }
    },
  },
  mounted: function () {
    this.getFunctionTypes();
    this.getItem();
    this.form.a.rules = [this.rules.required, this.rules.number];
    this.form.b.rules = [this.rules.required, this.rules.number];
  },
  methods: {
    getFunctionTypes() {
      this.$store
        .dispatch("calibrator/getFunctionTypes")
        .then((data) => {
          this.functionTypes = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSubmit(stay) {
      if (this.validateForm()) {
        this.sendItem(stay);
      }
    },
    getItem() {
      this.$store
        .dispatch("calibrator/getItem", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
        })
        .then((data) => {
          let item = data.calibrator;
          if (item) {
            this.form.a.value = Number.parseFloat(item.a);
            this.form.b.value = Number.parseFloat(item.b);
            this.form.c.value = Number.parseFloat(item.c);
            this.form.numberOfDecimals.value = item.number_of_decimals;
            this.form.functionTypeId.value = item.function_type_id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendItem(stay) {
      this.$store
        .dispatch("calibrator/sendItem", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
          calibrator: {
            a: this.form.a.value,
            b: this.form.b.value,
            c: this.form.c.value,
            number_of_decimals: this.form.numberOfDecimals.value,
            function_type_id: this.form.functionTypeId.value,
          },
        })
        .then(() => {
          this.$notify({
            message: "The calibrator was updated",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          if (stay === "goBack") {
            this.goBackWithDelay();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            message: "Something went wrong",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    goBackWithDelay() {
      setTimeout(() => {
        this.$router.push(
          "/devices/edit/" +
            this.$route.params.device +
            "/slots/edit/" +
            this.$route.params.slot
        );
      }, 1000);
    },
  },
};
</script>