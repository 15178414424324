export const validator = {
  data: () => {
    return {
      rules: {
        required: value => !!value || 'This field is required',
        password: value =>
          value.length >= 6 || 'The password must have at least 6 characters',
        number: value => /[+-]?([1-9]\d*(\.\d*[1-9])?|0\.\d*[1-9]+)|\d+(\.\d*[1-9])?/g.test(value) || 'This field must be a valid number',
        email: value =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value,
          ) || 'This field must be a valid email',
        reference: value => /\S/.test(value)
      },
    };
  },
  methods: {
    evaluateInput(event) {
      setTimeout(() => {
        let { name, value } = event.target;
        let evaluation = this.validateInput(this.form[name].rules, value);
        evaluation !== true
          ? (this.form[name].error = evaluation)
          : (this.form[name].error = '');
      }, 0);
    },
    cleanErrors() {
      for (let item in this.form) {
        item.error = '';
      }
    },
    cleanForm() {
      for (let item in this.form) {
        if (Object.prototype.hasOwnProperty.call(this.form[item], 'default')) {
          this.form[item].value = this.form[item].default;
        } else {
          this.form[item].value = '';
        }
        this.form[item].error = '';
      }
    },
    validateForm() {
      let isValid = true;
      Object.keys(this.form).forEach(key => {
        let evaluation = this.validateInput(
          this.form[key].rules,
          this.form[key].value,
        );
        evaluation !== true
          ? ((this.form[key].error = evaluation), (isValid = false))
          : (this.form[key].error = '');
      });
      return isValid;
    },
    validateInput(rules, value) {
      let evaluation = true;
      for (let rule of rules) {
        evaluation = rule(value);
        if (evaluation !== true) {
          break;
        }
      }
      return evaluation;
    },
  },
};