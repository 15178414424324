export const calibratorFunctions = {
    methods: {
      getSign(params) {
        let symbol = '';
        let regexp = /[+-]?([1-9]\d*(\.\d*[1-9])?|0\.\d*[1-9]+)|\d+(\.\d*[1-9])?/g;
        for (let i = 0; i < params.length; i++) {
            if(regexp.test(params[i]) && !isNaN(Math.abs(params[i])) && params[i] != ""){
                if (params[i] < 0){
                    if(i === 0){
                        symbol = '-';
                    }else{
                        if(symbol === '-'){
                            symbol = '+';
                        }
                    }
                }else{
                    if(i === 0){
                        symbol = '+';
                    }else{
                        if(symbol === '-'){
                            symbol = '-';
                        }
                    }
                }
            }else{
                symbol = "±";
                break;
            }
        }
        return symbol;
      },
    },
  };