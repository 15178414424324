<template>
    <p>
        f (x) =
        {{ a != "" && !isNaN(a) ? a : "a" }}x
        {{ sign }}
        {{
        !isNaN(Math.abs(b)) && b != ""
            ? Math.abs(b)
            : "b"
        }}
    </p>
</template>

<script>
import { calibratorFunctions } from "../../mixins/calibratorFunctions.js";
export default {
  mixins: [calibratorFunctions],
  props: ['a', 'b'],
  name: 'linear-function',
  data() {
    return {
      sign: '±',
    };
  },
  watch: {
      b() {
          this.setSigns();
      },
  },
  mounted: function () {
    this.setSigns();
  },
  methods: {
    setSigns() {
      this.sign = this.getSign([this.b]);
    },
  },
};
</script>